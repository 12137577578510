<template>
    <div class="flex justify-center items-center h-full">
        <div class="relative flex flex-col bg-white p-10 sm:p-12 shadow-md rounded w-auto  sm:w-104">
            <img src="@/assets/images/logo_color.png" class="self-center mb-4" width="200px" alt="">
            <form class="flex flex-col border-t border-gray-300 pt-6">
                <input
                    v-model="email"
                    class="border border-gray-400 rounded py-2 px-3 mb-4 placeholder-gray-400 outline-none focus:border-indigo-300 transition"
                    type="email"
                    placeholder="E-mail"
                    required
                    @keyup.enter="resetPassword"
                >
                <button
                    v-wait:hidden="'logging'"
                    class="bg-indigo-500 text-white self-end py-2 px-6 rounded shadow hover:bg-indigo-400 transition outline-none focus:outline-none active:bg-blue-700"
                    @click.prevent="resetPassword"
                >
                    {{ $t('reset_password_page.reset_password') }}
                </button>
                <button
                    v-wait:visible="'logging'"
                    disabled
                    class="relative bg-indigo-500 text-white self-end py-2 px-6 rounded shadow hover:bg-indigo-400 transition outline-none focus:outline-none active:bg-blue-700"
                >
                    <pulse-loader :loading="true" color="#b5c7ff" size="10px" />
                </button>
            </form>
            <p
                class="relative sm:absolute bottom-0 mt-5 sm:mt-0 sm:mb-3 self-center text-sm text-red-500 transition"
                :class="showErrorMessage || 'hidden'"
            >
                {{ $t('reset_password_page.incorrect_email_please_try_again') }}
            </p>
            <p
                class="relative sm:absolute bottom-0 mt-5 sm:mt-0 sm:mb-3 self-center text-sm text-green-500 transition"
                :class="showSuccessMessage || 'hidden'"
            >
                {{ $t('reset_password_page.success_please_check_your_email_and_follow_the_instructions') }}
            </p>
        </div>
    </div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
    components: {
        PulseLoader,
    },

    data() {
        return {
            email:              '',
            showErrorMessage:   false,
            showSuccessMessage: false,
        };
    },

    methods: {
        resetPassword() {
            if (!this.firstValidation()) return false;
            this.sendRequest();
        },

        async sendRequest() {
            this.$wait.start('logging');

            try {
                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/password_reset`, {
                    email: this.email,
                });
                this.responseSuccess();
            } catch ($error) {
                this.responseError();
            }
        },

        firstValidation() {
            this.showErrorMessage = false;
            this.showSuccessMessage = false;

            if (!this.email) {
                this.showErrorMessage = true;
                return false;
            }

            return true;
        },

        responseSuccess() {
            this.showErrorMessage = false;
            this.showSuccessMessage = true;
            this.email = '';
            this.$wait.end('logging');
        },

        responseError() {
            this.email = '';
            this.showErrorMessage = true;
            this.showSuccessMessage = false;
            this.$wait.end('logging');
        },
    },
};
</script>
